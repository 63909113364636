// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogg-index-js": () => import("./../../../src/pages/blogg/index.js" /* webpackChunkName: "component---src-pages-blogg-index-js" */),
  "component---src-pages-event-index-js": () => import("./../../../src/pages/event/index.js" /* webpackChunkName: "component---src-pages-event-index-js" */),
  "component---src-pages-kontakt-examples-js": () => import("./../../../src/pages/kontakt/examples.js" /* webpackChunkName: "component---src-pages-kontakt-examples-js" */),
  "component---src-pages-kontakt-file-upload-js": () => import("./../../../src/pages/kontakt/file-upload.js" /* webpackChunkName: "component---src-pages-kontakt-file-upload-js" */),
  "component---src-pages-kontakt-thanks-js": () => import("./../../../src/pages/kontakt/thanks.js" /* webpackChunkName: "component---src-pages-kontakt-thanks-js" */),
  "component---src-pages-kurser-index-js": () => import("./../../../src/pages/kurser/index.js" /* webpackChunkName: "component---src-pages-kurser-index-js" */),
  "component---src-pages-staff-index-js": () => import("./../../../src/pages/staff/index.js" /* webpackChunkName: "component---src-pages-staff-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-event-post-js": () => import("./../../../src/templates/event-post.js" /* webpackChunkName: "component---src-templates-event-post-js" */),
  "component---src-templates-growth-page-js": () => import("./../../../src/templates/growth-page.js" /* webpackChunkName: "component---src-templates-growth-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-staff-js": () => import("./../../../src/templates/staff.js" /* webpackChunkName: "component---src-templates-staff-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

